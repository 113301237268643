import axios from "axios";

export async function retrieveLayoutData() {
  const layoutApiUrl: string = process.env.REACT_APP_API_LAYOUT_URL || "";

  if (!layoutApiUrl) {
    return [];
  }

  const { data } = await axios.get(process.env.REACT_APP_API_LAYOUT_URL || "");

  const isProd = process.env?.REACT_APP_ENVIRONMENT === "production";

  data.footer?.columns[2].push(
    ...[
      {
        url: "mailto:strompris@forbrukerradet.no",
        title: "strompris@forbrukerradet.no",
        classList: "",
      },
      {
        url: isProd
          ? "https://admin-strom.forbrukerradet.no/login"
          : "https://admin-strom.forbrukerhelt.no/login",
        title: "Logg inn (for strømselskaper)",
        classList: "external-link",
      },
    ]
  );

  return data;
}
