import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { MatomoProvider, createInstance } from "@jonkoops/matomo-tracker-react";
import ConditionalWrapperComponent from "./components/ConditionalWrapperComponent";

const matomoHost = process.env.REACT_APP_MATOMO_HOST || "";
const matomoSiteId = parseInt(process.env.REACT_APP_MATOMO_SITE_ID || "") || 0;
const matomoSrcUrl = process.env.REACT_APP_MATOMO_SRC_PATH || "";

const hasMatomo: boolean = matomoHost && matomoSiteId ? true : false;

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <ConditionalWrapperComponent
      condition={hasMatomo}
      wrapper={(children: JSX.Element) => (
        <MatomoProvider
          value={createInstance({
            urlBase: matomoHost,
            siteId: matomoSiteId,
            srcUrl: matomoSrcUrl,
          })}
        >
          {children}
        </MatomoProvider>
      )}
    >
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ConditionalWrapperComponent>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
