import { Suspense, lazy } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { HelmetProvider, Helmet } from "react-helmet-async";
import {
  Link,
  Routes,
  Route,
  useNavigate,
  useLocation,
} from "react-router-dom";
import classNames from "classnames";
import Footer from "./layouts/Footer";
import Header from "./layouts/Header";
import "./App.scss";

const Stromavtaler = lazy(() => import("./components/Stromavtaler"));
const Spotpriser = lazy(() => import("./components/Spotpriser"));

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = new QueryClient();

  // Build cdn url
  const cdnHost: string | undefined = process.env.REACT_APP_CDN_HOST;
  const cdnVersion: string | undefined = process.env.REACT_APP_CDN_VERSION;
  const cdnUrl: string = `${cdnHost}${
    cdnVersion?.match(/^[0-9]+\.[0-9]+\.[0-9]+$/) ? "v" : ""
  }${cdnVersion ? cdnVersion + "/" : ""}`;

  const isSpotprices: boolean =
    location?.pathname === `${process.env?.REACT_APP_PATH || ""}/spotpriser`;

  const activeTabName = isSpotprices ? "Spotpriser" : "Sammenlign strømavtaler";
  return (
    <QueryClientProvider client={queryClient}>
      <HelmetProvider>
        <Helmet>
          {cdnHost && (
            <>
              <link
                rel="stylesheet"
                type="text/css"
                href={`${cdnUrl}vendor.css`}
              />
              <link
                rel="stylesheet"
                type="text/css"
                href={`${cdnUrl}main.css`}
              />
            </>
          )}
        </Helmet>
        <Header />
        <section className="center-hero surface-secondary">
          <div className="container">
            <div className="breadcrumbs">
              <a href="https://www.forbrukerradet.no">Forsiden</a>
              <i className="fa-regular fa-chevron-right"></i>
              {!isSpotprices ? (
                <span className="current">Strømpris</span>
              ) : (
                <>
                  <Link to="/strompris">Strømpris</Link>
                  <i className="fa-regular fa-chevron-right"></i>
                  <span className="current">Spotpriser</span>
                </>
              )}
            </div>
            <div className="hero-text">
              <h1>{activeTabName}</h1>

              <p className="fs-h3 mt-3">
                {isSpotprices
                  ? "Vi viser deg spotprisen time for time fra Strømbørsen Nord Pool."
                  : "Vi overvåker markedet og viser de beste strømavtalene for deg."}
              </p>
              <p className="fs-h4 mt-3">
                <a
                  href="https://www.forbrukerradet.no/forside/bolig/strom/"
                  target="_blank"
                  rel="noreferrer"
                  className="flex justify-content-center external-link underline"
                >
                  <span className="mr-4">Les mer om strøm</span>
                  <i className="fa-regular fa-arrow-up-right-from-square" />
                </a>
              </p>

              <div className="flex content-center gap-5 gap-md-4 gap-sm-3 mt-7">
                <button
                  className={classNames("btn btn-secondary tab", {
                    "is-active": !isSpotprices,
                  })}
                  onClick={() =>
                    navigate(`${process.env?.REACT_APP_PATH || ""}/`)
                  }
                >
                  Sammenlign avtaler
                </button>

                <button
                  className={classNames("btn btn-secondary tab", {
                    "is-active": isSpotprices,
                  })}
                  onClick={() =>
                    navigate(`${process.env?.REACT_APP_PATH || ""}/spotpriser`)
                  }
                >
                  Spotpriser
                </button>
              </div>
            </div>
          </div>
        </section>
        <section className="container mt-11">
          <Suspense
            fallback={
              <div className="flex content-center">
                <span className="spinner"></span>
              </div>
            }
          >
            <Routes>
              <Route
                path={`${process.env?.REACT_APP_PATH || ""}/spotpriser`}
                element={<Spotpriser />}
              />
              <Route
                path={`${process.env?.REACT_APP_PATH || ""}`}
                element={<Stromavtaler />}
              />
            </Routes>
          </Suspense>
        </section>
        <Footer />
      </HelmetProvider>
    </QueryClientProvider>
  );
}

export default App;
