import React from "react";
import { Footer as FooterComponent } from "frno-react";
import useStore from "../state/state";
import useLayout from "../hooks/useLayout";

const Footer: React.FC = () => {
  useLayout();
  const layout = useStore((state) => state.layout);

  if (!layout.footer) {
    return null;
  }

  return (
    <FooterComponent
      links={layout.footer.columns}
      social={layout.footer.social}
    />
  );
};

export default Footer;
