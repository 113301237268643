import { useQuery } from 'react-query';
import useStore from '../state/state';
import { retrieveLayoutData } from "../util/layout";

export default function useLayout() {
    const setLayout = useStore((state) => state.setLayout);
    return useQuery("layout", async () => await retrieveLayoutData(), {
        initialData: [],
        retry: 3,
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
            setLayout(data);
        },
    });
};