import React from 'react';
import { Header as HeaderComponent, Button } from 'frno-react';
import useStore from '../state/state';
import useLayout from '../hooks/useLayout';

const Header: React.FC = () => {
    useLayout();
    const layout = useStore(state => state.layout);
    
    if(!layout.nav) {
        return null;
    }

    return (
        <HeaderComponent
            logo='https://www.forbrukerradet.no/wp-content/themes/frweb22/dist/forbrukerradet_logo.svg'
            logoSmall='https://www.forbrukerradet.no/wp-content/themes/frweb22/dist/forbrukerradet_logo_symbol.svg'
            expandableMenuColumns={layout.nav.mega_menu}
            homeLink={'https://www.forbrukerradet.no/'}
        >
            { layout.nav.container.map((item, i) => (<Button
                key={i}
                href={item.url}
                className={`${item.classList ?? ""} button-default`}
            >
                {item.title}
            </Button>)) }
        </HeaderComponent>
    );
};

export default Header;
