const ConditionalWrapperComponent = ({
  condition,
  wrapper,
  children,
}: {
  condition: boolean;
  wrapper: Function;
  children: JSX.Element;
}) => (condition ? wrapper(children) : children);

export default ConditionalWrapperComponent;
