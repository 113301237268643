import { create } from "zustand";
import {
  IDayPrice,
  IHistoricPrices,
  DealType,
  DealInterval,
  Commune,
  StromFilter,
  INewProducts,
} from "../shared/types";
import { format, sub } from "date-fns";

type NavLink = {
  url: string;
  title: string;
  classList: string;
};

type SocialAria = {
  label: string;
};

type SocialLink = {
  url: string;
  target: string;
  aria?: SocialAria;
  icon: string;
};

type NavLayout = {
  container: NavLink[];
  mega_menu: Array<NavLink[]>;
};

type FooterLayout = {
  columns: Array<NavLink[]>;
  social: SocialLink[];
};

type Layout = {
  nav?: NavLayout;
  footer?: FooterLayout;
};

type State = {
  stromFilter: StromFilter;
  setCurrentCommune: (commune: Commune) => void;
  setKwh: (kwh: number) => void;
  setSearch: (search: string) => void;
  setDealType: (dealType: DealType) => void;
  hasDealInterval: (interval: DealInterval) => boolean;
  setDealInterval: (interval: DealInterval) => void;
  unsetDealInterval: (interval: DealInterval) => void;
  clearDealType: () => void;
  setCustomerNew: (isNew: boolean) => void;
  setMembershipRequired: (isNew: boolean) => void;
  setCabinProduct: (val: boolean) => void;
  allProducts?: any[];
  products?: INewProducts;
  productLoading: boolean;
  setProductLoading: (productLoading: boolean) => void;
  setProducts: (products: INewProducts) => void;
  selectedProducts: any[];
  selectedProductsInComparison: any[];
  selectProduct: (product: any, inComparison: boolean) => void;
  unselectProduct: (product: any) => void;
  clearProduct: () => void;
  expandedProducts: number[];
  expandProduct: (productId: number) => void;
  unexpandProduct: (productId: number) => void;
  dayPrice?: IDayPrice;
  setDayPrice: (dayPrice: IDayPrice) => void;
  historyPrice?: IHistoricPrices;
  setHistoryPrice: (historyPrice: IHistoricPrices) => void;
  currentHistoryPrice?: IHistoricPrices;
  setCurrentHistoryPrice: (currentHistoryPrice: IHistoricPrices) => void;
  historyPeriod: string;
  setHistoryPeriod: (historyPeriod: string) => void;
  showComparison: boolean;
  setShowComparison: (val: boolean) => void;
  showDetails: number | undefined;
  setShowDetails: (val: number | undefined) => void;
  layout: Layout;
  setLayout: (layout: Layout) => void;
  isFiltersOpen: boolean;
  setIsFiltersOpen: (setIsFiltersOpen: boolean) => void;
  from7Days: string;
  from30Days: string;
  data7Days?: any;
  setData7Days: (data7Days: any) => void;
  data30Days?: any;
  setData30Days: (data7Days: any) => void;
};

const setLocalStorage = (key: string, value: any, ttl?: number) => {
  const data = {
    value: value,
    ttl: ttl ? Date.now() + ttl * 1000 : null,
  };
  localStorage.setItem(key, JSON.stringify(data));
};

const getLocalStorage = (key: string) => {
  const data = localStorage.getItem(key);
  if (!data) {
    return null;
  }

  const item = JSON.parse(data);
  if (item.ttl && Date.now() > item.ttl) {
    localStorage.removeItem(key);
    return null;
  }

  return item.value;
};

const useStore = create<State>((set, get) => ({
  productLoading: false,
  setProductLoading: (productLoading: boolean) => {
    set({ productLoading });
  },
  from7Days: format(sub(new Date(), { days: 7 }), "yyyy-MM-dd"),
  from30Days: format(sub(new Date(), { days: 30 }), "yyyy-MM-dd"),
  stromFilter: {
    kwh: getLocalStorage("kwh") || 16000,
    search: "",
    dealInterval: ["long", "medium"],
    dealType: "spot",
    customerNew: false,
    cabinProduct: false,
    membershipRequired: false,
    currentCommuneId: getLocalStorage("currentCommuneId") || 5,
  },
  selectedProducts: [],
  selectedProductsInComparison: [],
  expandedProducts: [],
  historyPeriod: "currentYear",
  showComparison: false,
  showDetails: undefined,
  layout: {},
  isFiltersOpen: false,
  setHistoryPeriod: (historyPeriod: string) => {
    set({ historyPeriod });
  },
  setCurrentCommune: (commune: Commune) => {
    set((state) => ({
      ...state,
      stromFilter: {
        ...state.stromFilter,
        currentCommune: commune,
        currentCommuneId: commune.id,
      },
    }));
    setLocalStorage("currentCommuneId", commune.id);
  },
  setKwh: (kwh: number) => {
    set((state) => ({
      ...state,
      stromFilter: {
        ...state.stromFilter,
        kwh: kwh,
      },
    }));
    setLocalStorage("kwh", kwh);
  },
  setSearch: (search: string) =>
    set((state) => ({
      ...state,
      stromFilter: {
        ...state.stromFilter,
        search: search,
      },
    })),

  setDealType: (dealType: DealType) =>
    set((state) => ({
      stromFilter: {
        ...state.stromFilter,
        dealType,
      },
    })),

  clearDealType: () =>
    set((state) => ({
      stromFilter: {
        ...state.stromFilter,
        dealType: "",
      },
    })),

  setCustomerNew: (isNew: boolean) =>
    set((state) => ({
      stromFilter: {
        ...state.stromFilter,
        customerNew: isNew,
      },
    })),

  setMembershipRequired: (isNew: boolean) =>
    set((state) => ({
      stromFilter: {
        ...state.stromFilter,
        membershipRequired: isNew,
      },
    })),

  setCabinProduct: (val: boolean) =>
    set((state) => ({
      stromFilter: {
        ...state.stromFilter,
        cabinProduct: val,
      },
    })),

  hasDealInterval: (dealInterval: DealInterval) => {
    const interval = get().stromFilter.dealInterval;
    if (interval === undefined || interval.length === 0) {
      return false;
    }
    return interval.includes(dealInterval);
  },
  setDealInterval: (interval: DealInterval) =>
    set((state) => ({
      stromFilter: {
        ...state.stromFilter,
        dealInterval: [...(state.stromFilter.dealInterval || []), interval],
      },
    })),
  unsetDealInterval: (interval: DealInterval) =>
    set((state) => ({
      stromFilter: {
        ...state.stromFilter,
        dealInterval: state.stromFilter.dealInterval?.filter(
          (i) => i !== interval
        ),
      },
    })),

  setProducts: (products: INewProducts) =>
    set((state) => ({
      products: products,
    })),

  setDayPrice: (dayPrice: IDayPrice) =>
    set((state) => ({
      dayPrice,
    })),

  setData7Days: (data7Days: any) =>
    set((state) => ({
      data7Days,
    })),

  setData30Days: (data30Days: any) =>
    set((state) => ({
      data30Days,
    })),

  setHistoryPrice: (historyPrice: IHistoricPrices) =>
    set((state) => ({
      historyPrice,
    })),

  setCurrentHistoryPrice: (currentHistoryPrice: IHistoricPrices) =>
    set((state) => ({
      currentHistoryPrice,
    })),

  selectProduct: (product: any, inComparison: boolean = false) =>
    set((state) => ({
      selectedProducts: [...state.selectedProducts, product],
      selectedProductsInComparison: inComparison
        ? [...state.selectedProductsInComparison, product]
        : state.selectedProductsInComparison,
    })),

  unselectProduct: (product: any) =>
    set((state) => ({
      selectedProducts: state.selectedProducts.filter(
        (p: any) => p.id !== product.id
      ),
      selectedProductsInComparison: state.selectedProductsInComparison.filter(
        (p: any) => p.id !== product.id
      ),
    })),

  clearProduct: () =>
    set((state) => ({
      selectedProducts: [],
      selectedProductsInComparison: [],
    })),

  expandProduct: (productId: number) =>
    set((state) => ({
      expandedProducts: [...state.expandedProducts, productId],
    })),

  unexpandProduct: (productId: number) =>
    set((state) => ({
      expandedProducts: state.expandedProducts.filter((id) => id !== productId),
    })),

  setShowComparison: (val: boolean) =>
    set((state) => ({
      showComparison: val,
    })),
  setShowDetails: (val: number | undefined) =>
    set((state) => ({
      showDetails: val,
    })),

  setLayout: (layout: Layout) =>
    set((state) => ({
      ...state,
      layout,
    })),

  setIsFiltersOpen: (isFiltersOpen: boolean) =>
    set((state) => {
      if (isFiltersOpen) {
        document.getElementsByTagName("html")[0]?.classList.add("no-scroll");
        document.body.classList.add("no-scroll");
      } else {
        document.getElementsByTagName("html")[0]?.classList.remove("no-scroll");
        document.body.classList.remove("no-scroll");
      }

      return {
        ...state,
        isFiltersOpen,
      };
    }),
}));

export default useStore;
